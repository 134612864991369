import apex from '../images/apexfivestar.webp'
import rust from '../images/rustfivestar.webp'
import rainbow from '../images/rainbowfivestar.webp'
import tarkov from '../images/tarkovfivestar.webp'
import cs2 from '../images/counterstrikefivestar.webp'
import spooferexception from '../images/spoofer/spooferexception.webp';

export const PopularGames = [
    {
        "game": "Rust",
        "image": rust
    },
    {
        "game": "Escape from Tarkov",
        "image": tarkov
    },
    {
        "game": "Hardware Spoofer",
        "image": spooferexception
    },
    {
        "game": "Apex",
        "image": apex
    },
]