import React, {useState, useEffect, useRef} from "react";
import { BrowserRouter as Router, Routes, Link, Route} from 'react-router-dom';
import {Footer} from '../components/footer.js'
import {data} from '../components/cheats-data.js';
import {Games} from '../components/games.js';

export const Faq = () => {

   const [activeIndex, setActiveIndex] = useState([]);
 
   const toggleFaq = (index) => {
      if (activeIndex.includes(index)) {
        // If the index is already in the array, remove it
        console.log("Removed");
        setActiveIndex(activeIndex.filter((item) => item !== index));
      } else {
        // If the index is not in the array, add it
        console.log("Added");
        setActiveIndex([...activeIndex, index]);
      }
    };

   const checkFaq = (index) => {
      let doesExist = activeIndex.filter((index) => index === 0).length > 0;

      return doesExist;
   }

 return (
   <>
   <div className="faq">
      <div className="faq-title">FAQ</div>
      <div onClick={() => toggleFaq(0)} className={activeIndex.includes(0) ? "faq-card faq-card-active" : "faq-card"}>
         <div className="faq-question">
            <span>Are the products safe?</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 24 24"><path fill="#fff4ff" d="m12 15l-5-5h10l-5 5Z"/></svg>
         </div>
         <div className="faq-answer">
         There is always a risk of a ban even with an “Undetected” product because there is always a chance it can get detected during your play time, if you
         plan on using cheats we recommend you do not use an account you care about. But we will very actively update our status page so you can at all times
         know if something is safe to use or not.
         </div>
      </div>
      <div onClick={() => toggleFaq(1)} className={activeIndex.includes(1) ? "faq-card faq-card-active" : "faq-card"}>
         <div className="faq-question">
            <span>Why should i buy from Disconnect.wtf?</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 24 24"><path fill="#fff4ff" d="m12 15l-5-5h10l-5 5Z"/></svg>
         </div>
         <div className="faq-answer">
            Disconnect.wtf is better then competitors for multiple reasons. We started as buyers ourselfs and have a good idea of what people want. 
            we price match with our competitors to be the most affordable sellers on the market. we update our status page as soon as something changes
            so our customers can review this before using a product. this way we hope to achieve the best possible experience when buying from Disconnect.wtf.
         </div>
      </div>
      <div onClick={() => toggleFaq(2)} className={activeIndex.includes(2) ? "faq-card faq-card-active" : "faq-card"}>
         <div className="faq-question">
            <span>Are there refunds?</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 24 24"><path fill="#fff4ff" d="m12 15l-5-5h10l-5 5Z"/></svg>
         </div>
         <div className="faq-answer">
         We have a strict EULA Policy (End User License Agreement). In short we reserve the right to deny any refund at any time. 
         Since our services provide electronic, digital goods we do not offer refunds on used digital goods. 
         You will not receive any refund for partial usage of the services provided, please make sure the subscription you are going to purchase is right for you. 
         Any queries or arising issues that are not the customers fault can be resolved through the provided support systems.         </div>
      </div>
      <div onClick={() => toggleFaq(3)} className={activeIndex.includes(3) ? "faq-card faq-card-active" : "faq-card"}>
         <div className="faq-question">
            <span>Is it instant delivery?</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 24 24"><path fill="#fff4ff" d="m12 15l-5-5h10l-5 5Z"/></svg>
         </div>
         <div className="faq-answer">
         Delivery is instant. The delivery can be found in your email and/or on the website. If you have trouble finding it, create a discord ticket.         </div>
      </div>
      <div onClick={() => toggleFaq(4)} className={activeIndex.includes(4) ? "faq-card faq-card-active" : "faq-card"}>
         <div className="faq-question">
            <span>You want to become a reseller?</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="27" viewBox="0 0 24 24"><path fill="#fff4ff" d="m12 15l-5-5h10l-5 5Z"/></svg>
         </div>
         <div className="faq-answer">
         We are always looking for resellers. Join our discord and open a ticket or DM shadower0001 on Discord.</div>
      </div>
   </div>
    </>

 )

}